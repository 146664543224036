<template>
  <Navbar />
  <div id="home-page">
    <!-- Hero Section -->
    <section class="slideshow-section">
      <div class="slideshow-container">
        <div v-for="(slide, index) in slides" :key="index" class="slide" :class="{ active: activeIndex === index }">
          <div class="text-content">
            <h1>{{ slide.title }}</h1>
            <p>{{ slide.description }}</p>
            <button type="button" class="submit-button-cls">Get Started Today</button>
          </div>
          <div class="image-wrapper background-image"></div>
        </div>
      </div>
      <div class="star-wars-tabs">
        <div v-for="(slide, index) in slides" :key="`tab-${index}`" class="tab"
          :class="{ active: activeIndex === index }" @click="setActiveSlide(index)">
          <div class="tab-inner">
            <span class="tab-icon">&#9679;</span>
            <span class="tab-text">{{ slide.title }}</span>
          </div>
        </div>
      </div>
    </section>

    <!-- Value Proposition Section -->
    <section id="value-proposition" class="value-proposition-section">
      <div class="container">
        <h2>Unleash the Power of Machine Learning – No Code Required</h2>
        <p>
          Our platform brings the full capabilities of machine learning to your fingertips
          without the need for coding skills. From data preparation to deployment, we
          empower you with a seamless, cost-effective, and efficient journey through the
          entire ML process.
        </p>
        <div class="features-grid">
          <div class="feature-item">
            <h3>Easy-to-Use Interface</h3>
            <p>
              Designed with simplicity in mind, our platform allows you to manage every
              aspect of your ML projects without the complexity.
            </p>
          </div>
          <div class="feature-item">
            <h3>Extensive Model Library</h3>
            <p>
              Access a comprehensive range of models, tailored to fit the unique demands
              of your industry and data.
            </p>
          </div>
          <div class="feature-item">
            <h3>Cost-Effective Solutions</h3>
            <p>
              Get enterprise-level ML capabilities at a fraction of the cost. Our pricing
              is built to grow with your needs.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- User Journey Section -->
    <section id="user-journey" class="user-journey-section">
      <div class="container">
        <div class="journey-header">
          <h2>Your ML Journey in 4 Simple Steps</h2>
          <div class="progress-container">
            <div class="progress-text">{{ progressText }}</div>
            <div class="progress-bar" id="progress-bar"></div>
          </div>
        </div>
        <div class="journey-steps">
          <div class="step" id="step-1">
            <div class="step-content">
              <h3>Data Preparation</h3>
              <p>
                Easily upload, clean, and transform your data. Our preprocessing tools do
                the heavy lifting so you can focus on insights.
              </p>
            </div>
          </div>
          <div class="step" id="step-2">
            <div class="step-content">
              <h3>Model Selection</h3>
              <p>
                Choose from over 40 pre-built models or let our AutoML recommend the best
                model for your data and goals.
              </p>
            </div>
          </div>
          <div class="step" id="step-3">
            <div class="step-content">
              <h3>Training and Evaluation</h3>
              <p>
                Train models with manual controls or use our advanced AutoML for one-click
                training. Evaluate results with clear, actionable insights.
              </p>
            </div>
          </div>
          <div class="step" id="step-4">
            <div class="step-content">
              <h3>Visualization and Deployment</h3>
              <p>
                Create dynamic dashboards, deploy your models via API, or export them for
                local use. Your insights, your way.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Use Cases Section -->
    <section id="use-cases" class="use-cases-section">
      <div class="container">
        <h2>Transform Your Industry with AI</h2>
        <div class="use-case-grid">
          <div class="use-case">
            <h3>Finance</h3>
            <p>
              Predict trends, optimize portfolios, and assess risks with powerful
              regression models tailored for financial data.
            </p>
          </div>
          <div class="use-case">
            <h3>Healthcare</h3>
            <p>
              Enhance patient care through predictive analytics, early diagnosis, and
              customized treatment plans.
            </p>
          </div>
          <div class="use-case">
            <h3>Retail</h3>
            <p>
              Maximize sales, personalize experiences, and optimize inventory with
              predictive analytics and clustering tools.
            </p>
          </div>
          <div class="use-case">
            <h3>Manufacturing</h3>
            <p>
              Improve efficiency, reduce downtime, and predict equipment failures with
              predictive maintenance models.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Learning Resources Section -->
    <section id="learning" class="learning-section">
      <div class="container">
        <h2>Learn and Grow with Expert ML Courses</h2>
        <p>
          Whether you’re a beginner or an expert, our courses are designed to elevate your
          skills and maximize the platform's potential.
        </p>
        <div class="course-highlights">
          <div class="course">
            <h3>Platform Mastery</h3>
            <p>
              Dive deep into every feature our platform offers and learn how to make the
              most of each tool.
            </p>
          </div>
          <div class="course">
            <h3>ML Fundamentals</h3>
            <p>
              Master the basics of machine learning, from algorithms to model
              optimization.
            </p>
          </div>
          <div class="course">
            <h3>Industry-Specific ML</h3>
            <p>
              Learn how to apply ML solutions to real-world challenges in finance,
              healthcare, agriculture, and more.
            </p>
          </div>
        </div>
        <button type="button" class="submit-button-cls">Explore Full Catalog</button>
      </div>
    </section>

    <!-- Pricing Section -->
    <section id="pricing" class="pricing-section">
      <div class="container">
        <h2>Flexible Pricing, Unmatched Value</h2>
        <p>Choose a plan that grows with your business needs.</p>
        <div class="pricing-grid">
          <div class="pricing-plan">
            <h3>Starter</h3>
            <div class="price">$99<span>/month</span></div>
            <div class="tokens">50,000 tokens/month</div>
            <ul class="features-list">
              <li>30GB data storage</li>
              <li>Basic AutoML</li>
            </ul>
            <a href="/pricing" class="plan-button">Get Started</a>
          </div>
          <div class="pricing-plan popular">
            <div class="popular-badge">Most Popular</div>
            <h3>Professional</h3>
            <div class="price">$299<span>/month</span></div>
            <div class="tokens">200,000 tokens/month</div>
            <ul class="features-list">
              <li>50GB data storage</li>
              <li>Advanced AutoML</li>
              <li>API access</li>
            </ul>
            <a href="/pricing" class="plan-button">Get Started</a>
          </div>
          <div class="pricing-plan">
            <h3>Business</h3>
            <div class="price">$799<span>/month</span></div>
            <div class="tokens">500,000 tokens/month</div>
            <ul class="features-list">
              <li>300GB data storage</li>
              <li>Full AutoML suite</li>
            </ul>
            <a href="/pricing" class="plan-button">Get Started</a>
          </div>
          <div class="pricing-plan enterprise">
            <h3>Enterprise</h3>
            <div class="price">Custom</div>
            <div class="tokens">Custom token allocation</div>
            <ul class="features-list">
              <li>Custom data storage</li>
              <li>Tailored AutoML solutions</li>
              <li>Dedicated account manager</li>
              <li>On-premise deployment option</li>
              <li>Volume-based token pricing</li>
            </ul>
            <a href="/contact" class="plan-button">Contact Us</a>
          </div>
        </div>
      </div>
    </section>
  </div>
  <Footer />
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import Navbar from "@/components/basic/NavBar.vue";
import Footer from "@/components/basic/FooterComponent.vue";

export default {
  name: "HomePage",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const slides = ref([
      {
        title: "No-Code Machine Learning Made Easy",
        description:
          "Upload your data, clean it up, and train models in minutes—no coding skills required. Simplify the entire ML workflow from start to finish.",
      },
      {
        title: "Automate Model Training with AutoML",
        description:
          "Choose the best model automatically or take control with manual selection. Our AutoML finds the optimal model, saving you time and boosting accuracy.",
      },
      {
        title: "Create Insights with Custom Dashboards",
        description:
          "Build interactive dashboards with drag-and-drop ease. Visualize predictions, track performance, and make data-driven decisions faster than ever.",
      },
    ]);

    const activeIndex = ref(0);
    let slideInterval = null;

    const startSlideshow = () => {
      slideInterval = setInterval(() => {
        activeIndex.value = (activeIndex.value + 1) % slides.value.length;
      }, 10000); // Change slide every 10 seconds
    };

    const stopSlideshow = () => {
      clearInterval(slideInterval);
    };

    // New method to handle tab clicks
    const setActiveSlide = (index) => {
      activeIndex.value = index;
      stopSlideshow();
      startSlideshow();
    };

    onMounted(() => {
      startSlideshow();
    });

    onBeforeUnmount(() => {
      stopSlideshow();
    });

    const progressText = ref("0%");
    const expandedLists = ref({
      regression: false,
      classification: false,
      timeSeries: false,
    });

    const handleScroll = () => {
      const journeySection = document.querySelector(".user-journey-section");
      const progressBar = document.getElementById("progress-bar");
      const steps = document.querySelectorAll(".step");
      const journeySectionTop = journeySection.offsetTop;
      const windowScroll = window.scrollY;

      let totalHeight = 0;
      steps.forEach((step) => {
        totalHeight += step.offsetHeight;
      });

      if (
        windowScroll >= journeySectionTop &&
        windowScroll <= journeySectionTop + totalHeight
      ) {
        const scrolled = ((windowScroll - journeySectionTop) / totalHeight) * 100;
        progressBar.style.width = scrolled + "%";
        progressText.value = `${Math.min(Math.ceil(scrolled), 100)}%`;
      } else if (windowScroll < journeySectionTop) {
        progressBar.style.width = "0%";
        progressText.value = "0%";
      } else if (windowScroll > journeySectionTop + totalHeight) {
        progressBar.style.width = "100%";
        progressText.value = "100%";
      }
    };

    const toggleList = (listType) => {
      expandedLists.value[listType] = !expandedLists.value[listType];
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      slides,
      activeIndex,
      progressText,
      expandedLists,
      toggleList,
      setActiveSlide, // New method added to the return object
    };
  },
};
</script>
